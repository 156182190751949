
import SEOChecker from './Components/SeoChecker';


function App() {
  return (
    <>

    <h1 style={{textAlign:"center",fontFamily:"times",color:"white",fontSize:"60px"}}>SEO Checker</h1>
    <SEOChecker/>

    </>
  );
}

export default App;
